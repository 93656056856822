import React from 'react';
import { Box, Divider, Grid, Paper, Typography } from '@material-ui/core';

import { useTranslation } from 'gatsby-plugin-react-i18next';
import { DotItem } from '../Item/DotItem';
import { DotListItem } from '../Item/DotListItem';

import { pricesStyles } from './styles';

const PricesPlan = () => {
  const { t } = useTranslation();
  const classes = pricesStyles();

  return (
    <>
      <Box mt={0}>
        <Box mb={3}>
          <Typography className={classes.contentHeadCenter}>{t('trans.pricesPlanHead')}</Typography>
          <Typography className={classes.contentHeadCenter}>{t('trans.pricesPlanHeadTwo')}</Typography>

          <Typography className={classes.contentHeadCategoryCenter}>
            <Box>
              <DotItem textEintragDot={t('trans.pricesPlanContent_1')} />
            </Box>
            <Box>
              <DotItem textEintragDot={t('trans.pricesPlanContent_2')} />
            </Box>
            <Box>
              <DotItem textEintragDot={t('trans.pricesPlanContent_3')} />
            </Box>
            <Box>
              <DotItem textEintragDot={t('trans.pricesPlanContent_4')} />
            </Box>
          </Typography>
        </Box>
        <Box className={classes.paperBox}>
          <Grid container spacing={6}>
            <Grid item lg={6} md={6} xs={12}>
              <Box>
                <Paper className={classes.paper}>
                  <Box className={classes.titelborder}>
                    <Typography className={classes.headingbold}>{t('trans.pricesPlanForFree')}</Typography>
                    <Typography className={classes.headingContent}>{t('trans.pricesPlanForFreeContent')}</Typography>
                  </Box>

                  <Box ml={1} mt={3}>
                    <Typography className={classes.headingtree}>{t('trans.pricesPlanInklusiveForFree')}</Typography>
                    <DotListItem icon="check" textEintragDot={t('trans.Instructionbuilder')} />
                    <DotListItem icon="check" textEintragDot={t('trans.TemplatingSystem')} />
                    <DotListItem icon="check" textEintragDot={t('trans.Questionnaire')} />
                    <DotListItem icon="check" textEintragDot={t('trans.CoreData')} />
                    <DotListItem icon="check" textEintragDot={t('trans.Date Monitoring')} />
                    <DotListItem icon="remove" textEintragDot={t('trans.Instructionarchive available for 12 months')} />
                    <DotListItem icon="clear" textEintragDot={t('trans.Unlimited Users')} />
                    <DotListItem icon="clear" textEintragDot={t('trans.Unlimited Instructions')} />
                    <DotListItem icon="clear" textEintragDot={t('trans.Visitors and External Instructions')} />
                  </Box>

                  <Box ml={1} mt={3}>
                    <Divider />
                  </Box>

                  <Box ml={1} mt={3}>
                    <Typography className={`${classes.fontWeightNormal} ${classes.small}`} variant="subtitle1">
                      {t('trans.free')}
                    </Typography>
                  </Box>
                </Paper>
              </Box>
            </Grid>
            <Grid item lg={6} md={6} xs={12}>
              <Box>
                <Paper className={classes.paper}>
                  <Box className={classes.titelborder}>
                    <Typography className={classes.headingbold}>{t('trans.pricesPlanBusiness')}</Typography>
                    <Typography className={classes.headingContent}>{t('trans.pricesPlanBusinessContent')}</Typography>
                  </Box>

                  <Box ml={1} mt={3}>
                    <Typography className={classes.headingtree}>{t('trans.pricesPlanInklusiveBusiness')}</Typography>
                    <DotListItem icon="check" textEintragDot={t('trans.Instructionbuilder')} />
                    <DotListItem icon="check" textEintragDot={t('trans.TemplatingSystem')} />
                    <DotListItem icon="check" textEintragDot={t('trans.Questionnaire')} />
                    <DotListItem icon="check" textEintragDot={t('trans.CoreData')} />
                    <DotListItem icon="check" textEintragDot={t('trans.Date Monitoring')} />
                    <DotListItem icon="check" textEintragDot={t('trans.Instructionarchive available for 12 months')} />
                    <DotListItem icon="check" textEintragDot={t('trans.Unlimited Users')} />
                    <DotListItem icon="check" textEintragDot={t('trans.Unlimited Instructions')} />
                    <DotListItem icon="check" textEintragDot={t('trans.Visitors and External Instructions')} />
                  </Box>

                  <Box ml={1} mt={3}>
                    <Divider />
                  </Box>

                  <Box ml={1} mt={3} display="flex" className={classes.pricesContainer}>
                    <Box flex="1">
                      <Typography className={classes.small} variant="subtitle1">
                        {t('trans.pricePerUser')}
                      </Typography>
                      <Typography className={`${classes.fontWeightNormal} ${classes.small}`} variant="subtitle1">
                        {t('trans.pricesPlanBusinessContent_1')}
                      </Typography>
                      <Typography className={`${classes.fontWeightNormal} ${classes.small}`} variant="subtitle1">
                        {t('trans.pricesPlanBusinessContent_2')}
                      </Typography>
                      <Typography className={`${classes.fontWeightNormal} ${classes.small}`} variant="subtitle1">
                        {t('trans.pricesPlanBusinessContent_3')}
                      </Typography>
                      <Typography className={`${classes.fontWeightNormal} ${classes.small}`} variant="subtitle1">
                        {t('trans.pricesPlanBusinessContent_4')}
                      </Typography>
                    </Box>
                    <Box flex="1">
                      <Typography className={classes.small} variant="subtitle1">
                        {t('trans.pricePerInstruction')}
                      </Typography>
                      <Typography className={`${classes.fontWeightNormal} ${classes.small}`} variant="subtitle1">
                        {t('trans.pricesPlanBusinessContent_5')}
                      </Typography>
                      <Typography className={`${classes.fontWeightNormal} ${classes.small}`} variant="subtitle1">
                        {t('trans.pricesPlanBusinessContent_6')}
                      </Typography>
                      <Typography className={`${classes.fontWeightNormal} ${classes.small}`} variant="subtitle1">
                        {t('trans.pricesPlanBusinessContent_7')}
                      </Typography>
                    </Box>
                  </Box>
                </Paper>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default PricesPlan;
