import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { BgImage } from 'gbimage-bridge';
import { Box, Container, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import ContentContainerPrices from './contentContainerPrices';

import PricesPlan from './pricesPlan';
import CalculatorButton from '../Button/calculatorButton';
import IntroductionContainerPices from './introductionContainerPices';
import { pricesStyles } from './styles';

const PricesBridged = () => {
  const { backgroundImagePrices } = useStaticQuery(
    graphql`
      query {
        backgroundImagePrices: file(relativePath: { eq: "euro1.png" }) {
          childImageSharp {
            gatsbyImageData(width: 2000, quality: 50, webpOptions: { quality: 70 })
          }
        }
      }
    `,
  );

  const pluginImage = getImage(backgroundImagePrices);
  const { t } = useTranslation();
  const classes = pricesStyles();
  return (
    <>
      <span id="pricesplan" className={classes.anker} />
      <BgImage image={pluginImage} className="masthead2 imagesBlur">
        <Grid container spacing={2} className={classes.headImagesTextPosition}>
          <Grid item lg={12} md={12} xs={12}>
            <Box className={classes.boxImagesHead}>
              <Typography className={classes.siteTitle}>{t('trans.pricesImagesHead')}</Typography>
              <Box mt={1.5}>
                <Typography className={classes.siteTitleContent}>{t('trans.pricesImagesContentOne')}</Typography>
              </Box>

              <Box mt={2}>
                <Typography className={classes.headheadingContentTour4}>{t('trans.laptopContentButton')}</Typography>
                <CalculatorButton />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </BgImage>

      <Container>
        <Box mt={9}>
          <Typography className={classes.headheadingSite}>{t('trans.prices')}</Typography>
        </Box>
      </Container>

      <Box mt={5} className="masthead2 mastheadlightblue">
        <Grid container spacing={2} className={classes.boxingallblue}>
          <Grid item lg={12} md={12} xs={12}>
            <PricesPlan />
          </Grid>
        </Grid>
      </Box>

      <Container>
        <Grid item lg={12} md={12} xs={12}>
          <Box className={classes.box}>
            <IntroductionContainerPices />
          </Box>
        </Grid>

        <Grid item lg={12} md={12} xs={12}>
          <Box className={classes.box}>
            <ContentContainerPrices />
          </Box>
        </Grid>
      </Container>
    </>
  );
};

export default PricesBridged;
