import { makeStyles } from '@material-ui/core/styles';

export const dotItemStyles = makeStyles((theme) => ({
  button: {
    color: '#aa0606',
  },
  check: {
    marginRight: theme.spacing(1),
    color: theme.palette.success.main,
  },
  cross: {
    marginRight: theme.spacing(1),
    color: theme.palette.error.main,
  },
  remove: {
    marginRight: theme.spacing(1),
    color: theme.palette.grey[600],
  },
}));
