import { makeStyles } from '@material-ui/core/styles';
import { COLOR_WHITE } from '../../theme/constants';

export const pricesStyles = makeStyles(() => ({
  boxingallblue: {
    display: 'inline-flex',
    maxWidth: '1260px',
    padding: '2em 1em 3em 1em',
  },
  fontWeightNormal: {
    fontWeight: 'normal',
  },
  small: {
    fontSize: '0.75rem',
    '@media (max-width: 500px)': {
      fontSize: '0.875rem',
    },
  },
  pricesContainer: {
    '@media (max-width: 500px)': {
      flexDirection: 'column',
      gap: '8px',
    },
  },

  headheadingSite: {
    color: '#080c2d',
    fontSize: '2.8rem',
    fontWeight: 900,
    textAlign: 'center',
    letterSpacing: '4px',
  },
  headheadingContent: {
    color: '#080c2d',
    fontSize: '1.4rem',
    fontWeight: 400,
    textAlign: 'center',
    letterSpacing: '0.5px',
  },
  siteTitle: {
    fontSize: '2.6rem',
    fontWeight: '600',
    color: COLOR_WHITE,
    marginTop: '50px',
    animation: 'textwelle 0.6s',
  },
  siteTitleContent: {
    fontSize: '1.3rem',
    fontWeight: '400',
    color: COLOR_WHITE,
    marginLeft: '0px',
    animation: 'textwelle 0.5s',
  },

  contentHeadLeft: {
    textAlign: 'left',
    fontSize: '2.0rem',
    fontWeight: '600',
    color: '#032963',
    marginTop: '2px',
    letterSpacing: '0.05rem',
  },
  contentHeadCenter: {
    textAlign: 'center',
    fontSize: '2.0rem',
    fontWeight: '600',
    color: '#032963',
    marginTop: '2px',
    letterSpacing: '0.05rem',
  },
  contentHeadCategoryLeft: {
    color: '#000038',
    fontSize: '1.1rem',
    fontWeight: '500',
    marginTop: '5px',
    textAlign: 'left',
  },
  contentHeadCategoryCenter: {
    color: '#000038',
    fontSize: '1.1rem',
    fontWeight: '500',
    marginTop: '5px',
    textAlign: 'center',
  },
  /*---------------------------------*/
  contentReportLeft: {
    textAlign: 'left',
    fontSize: '1.01rem',
    fontWeight: '400',
    color: '#080c2d',
    margin: '0px 0px 0px 0px ',
    letterSpacing: '0.02rem',
    padding: '0px 0px 0px 0px',
  },
  contentReportRight: {
    textAlign: 'right',
    fontSize: '1.01rem',
    fontWeight: '400',
    color: '#080c2d',
    margin: '0px 0px 0px 0px ',
    letterSpacing: '0.02rem',
    padding: '0px 0px 0px 0px',
  },
  contentReportCenter: {
    textAlign: 'center',
    fontSize: '1.01rem',
    fontWeight: '400',
    color: '#080c2d',
    margin: '0px 0px 0px 0px ',
    letterSpacing: '0.02rem',
    padding: '0px 0px 0px 0px',
  },
  contentReportLeftBold: {
    textAlign: 'left',
    fontSize: '1.01rem',
    fontWeight: '600',
    color: '#080c2d',
    margin: '0px 0px 0px 0px ',
    letterSpacing: '0.02rem',
    padding: '0px 0px 0px 0px',
  },
  contentReportRightBold: {
    textAlign: 'right',
    fontSize: '1.01rem',
    fontWeight: '600',
    color: '#080c2d',
    margin: '0px 0px 0px 0px ',
    letterSpacing: '0.02rem',
    padding: '0px 0px 0px 0px',
  },
  contentReportCenterBold: {
    textAlign: 'center',
    fontSize: '1.01rem',
    fontWeight: '600',
    color: '#080c2d',
    margin: '0px 0px 0px 0px ',
    letterSpacing: '0.02rem',
    padding: '0px 0px 0px 0px',
  },

  container: {
    minHeight: '100px',
  },

  contentboxContainer: {
    display: 'flex',
    maxWidth: '1440px',
    padding: '0.5em 1em 3em 0em',
  },

  contentbox: {
    display: 'flex',
    padding: '0em 1em 2em 0em',
  },

  contentContainerHeader: {
    marginTop: '5px',
  },

  contentContainer: {
    marginTop: '15px',
  },

  contentboxIntroduction: {
    display: 'flex',
    padding: '0em 1em 0em 0em',
  },

  contentboxcenter: {
    justifyItems: 'center',
  },

  contentleft: {
    textAlign: 'left',
    fontSize: '1.0rem',
    fontWeight: '400',
    color: '#000038',
    marginTop: '2px',
  },

  // ----------------------

  paper: {
    height: '100%',
    minHeight: '600px',
    padding: '10px 18px 10px 18px ',
    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
  },

  headingbold: {
    fontSize: '1.7rem',
    fontWeight: 600,
    textAlign: 'center',
    justifyContent: 'center',
    letterSpacing: '0.1rem',
  },

  headingContent: {
    fontSize: '1.0rem',
    fontWeight: 500,
    textAlign: 'center',
    justifyContent: 'center',
  },

  titelborder: {
    borderBottomStyle: 'solid',
    borderWidth: '0.13em thick',
    borderColor: '#ea5b27',
    marginBottom: '10px',
  },
  infobox: {
    height: '160px',
    padding: '5px 0px 0px 10px ',
    marginTop: '18px',
    color: '#000038',
    // backgroundColor: '#f2efef',
    borderRadius: '4px',
    borderStyle: 'solid',
    borderWidth: '0.1em',
    borderColor: '#f2efef',
    // boxShadow: '2px 2px 6px #a0a0a0',
  },

  headingone: {
    fontSize: '1.0rem',
    fontWeight: 600,
    margin: '5px 2px 1px 6px ',
  },
  headingtwo: {
    fontSize: '0.9rem',
    fontWeight: 500,
    margin: '0px 2px 0px 6px ',
  },
  headingtree: {
    fontSize: '1.2rem',
    fontWeight: 600,
    margin: '5px 2px 1px 2px ',
  },
  headingkuenbar: {
    fontSize: '0.9rem',
    fontWeight: 500,
    margin: '5px 2px 1px 6px ',
  },

  paperBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '50px auto 20px auto ',
  },
  anker: {
    marginTop: '-10px',
  },
  // --------------------
  box: {
    // display: 'inline-flex',
    minHeight: '100px',
    minWidth: 'auto',
    width: 'auto',
    margin: '0px 0px 0px 0px',
  },
  headheadingContentTour4: {
    fontStyle: 'normal',
    textAlign: 'left',
    fontSize: '0.95rem',
    fontWeight: '400',
    color: COLOR_WHITE,
    marginTop: '10px',
    padding: '0px 0px 0px 0px',
  },
  boxImagesHead: {
    minHeight: '300px',
  },
  headImagesTextPosition: {
    display: 'inline-flex',
    maxWidth: '1440px',
    padding: '4em 1em 0em 1em',
  },
}));
