import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';

import { useTranslation, Link } from 'gatsby-plugin-react-i18next';
import styled from 'styled-components';
import { pricesStyles } from './styles';

const LinkText = styled(Link)`
  display: inline-block;
  z-index: -2;
  text-decoration: none;
  color: #ea5e2a;

  &:hover {
    color: #ea5e2a;
    text-decoration: none;
  }
  &:after {
    opacity: 0;
    text-decoration: none;
  }
  &:hover&:after {
    opacity: 0;
    text-decoration: none;
  }
`;
const ContentContainerPrices = () => {
  const { t } = useTranslation();
  const classes = pricesStyles();
  return (
    <>
      <Grid container spacing={1} className={classes.contentboxContainer}>
        <Grid item lg={12} md={12} xs={12}>
          <Box className={classes.container}>
            <Typography className={classes.contentReportLeft}>{t('trans.contentContainerPricesContent_1')}</Typography>
            <Box mt={2.5}>
              <Typography className={classes.contentReportLeft}>
                {t('trans.contentContainerPricesContent_2')}
              </Typography>
            </Box>
            <Box mt={2.5}>
              <Typography className={classes.contentReportLeft}>
                {t('trans.contentContainerPricesContent_3')}
              </Typography>
            </Box>
            <Box mt={2.5}>
              <Typography className={classes.contentReportLeft}>
                {t('trans.contentContainerPricesContent_4')}{' '}
                <LinkText to="/contact/" title="Kontaktformular">
                  {t('trans.contentContainerPricesContent_4Link')}
                </LinkText>{' '}
                {t('trans.contentContainerPricesContent_5')}
              </Typography>
            </Box>
            <Box mt={2.5}>
              <Typography className={classes.contentReportLeft}>
                {t('trans.contentPricesContent_5')}
                <LinkText to="/calculator">
                  <span>{t('trans.contentPricesContent_5Link')}</span>
                </LinkText>
                {t('trans.contentPricesContent_5a')}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default ContentContainerPrices;
