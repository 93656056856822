import React from 'react';
import PropTypes from 'prop-types';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import { dotItemStyles } from '../styles';

export const DotItem = (props) => {
  const classes = dotItemStyles();
  const { textEintragDot } = props;
  return (
    <>
      <RadioButtonCheckedIcon className={classes.button} viewBox="-5 -56 27 50" />
      {textEintragDot}
    </>
  );
};

DotItem.propTypes = {
  textEintragDot: PropTypes.string.isRequired,
};
