import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import { CheckRounded, ClearRounded } from '@material-ui/icons';
import { dotItemStyles } from '../styles';

export const DotListItem = (props) => {
  const classes = dotItemStyles();
  const { textEintragDot, icon = 'check' } = props;

  const FeatureIcon = icon === 'check' || icon === 'remove' ? CheckRounded : ClearRounded;
  // eslint-disable-next-line no-nested-ternary
  const style = icon === 'check' ? classes.check : icon === 'remove' ? classes.remove : classes.cross;

  return (
    <Box display="flex" alignItems="center" mt={1}>
      <FeatureIcon className={style} />
      <Typography variant="body2">{textEintragDot}</Typography>
    </Box>
  );
};

DotListItem.propTypes = {
  textEintragDot: PropTypes.string.isRequired,
};
