import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';

import { useTranslation } from 'gatsby-plugin-react-i18next';

import { pricesStyles } from './styles';

const IntroductionContainerPices = () => {
  const { t } = useTranslation();
  const classes = pricesStyles();
  return (
    <>
      <Grid container spacing={1} className={classes.contentboxIntroduction}>
        <Grid item lg={12} md={12} xs={12}>
          <span id="calculator" />
          <Box mt={9} mb={1}>
            <Typography className={classes.contentHeadCenter}>{t('trans.introductionContainerPricesOne')}</Typography>
            <Typography className={classes.contentHeadCenter}>{t('trans.introductionContainerPricesTwo')}</Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default IntroductionContainerPices;
